<template>
  <div class="view pa24">
    <el-card class="box-card" shadow="never">
      <div slot="header" class="clearfix">
        <h3>1.佣金入账周期设置</h3>
      </div>
      <div>
        订单状态变为“已完成”后
        <el-input style="width: 70px" v-model="settlementDate" />
        天
      </div>
    </el-card>
    <el-card class="box-card mt20" shadow="never">
      <div slot="header" class="clearfix">
        <h3>2.打款方式</h3>
      </div>
      <div class="mb10">
        自动打款
        <el-switch v-model="switchValue"> </el-switch>
      </div>
      <span style="color: #7f7f7f"
        >提示：开启自动打款后，分销员佣金提现后将无需审核，佣金自动到账至分销者账户（微信将收取0.2%~0.6%的手续费）</span
      >
    </el-card>
    <el-card class="box-card mt20" shadow="never">
      <div slot="header" class="clearfix">
        <h3>3.佣金提现额度设置</h3>
      </div>
      <div class="mb10">
        佣金提现金额满
        <el-input-number style="width: 100px"
                         v-model="num"
                         controls-position="right"
                         ></el-input-number>
        元，才可提现
      </div>
      <span style="color: #7f7f7f"
        >提示：最低为2元，最高为200元，0表示默认</span
      >
    </el-card>
    <el-card class="box-card mt20" shadow="never">
      <div slot="header" class="clearfix">
        <h3>4.提现管理员设置</h3>
      </div>
      <div class="mb10">
       <el-input v-model="userName" class="myInput">
         <template slot="append"><span style="cursor: pointer" @click="selUser">选择人员</span></template>
       </el-input>
      </div>
    </el-card>
    <div class="mt20" style="text-align:center">
      <el-button  type="primary" plain @click="saveSetting" :disabled="!hasPermi">保存</el-button>
      <p class="mt10">提示：保存后即时生效，分销佣金都按照本设置入账</p>
    </div>
    <selUser ref="selUser"></selUser>
  </div>
</template>

<script>
import { updateSetting ,querySetting} from "@/api/financing";
import selUser from '@/pages/customPage/module/SelKf'
import {formatAmount} from "@/utils/utils";
export default {
  name: "commissionSettings",
  data() {
    return {
      hasPermi:true,
      switchValue: false,
      num:1,
      settlementDate:15,
      userId: '',
      userName: '111',
      settingId: 0
    };
  },
  components:{
    selUser
  },
  created(){
    this.getSetting()
    if(JSON.parse(localStorage.getItem('isSourceAdmin'))) {
      this.hasPermi = false
    }
  },
  methods:{
    getSetting(){
        let data =  {
        }
      querySetting(data)
      .then(res => {
        if(res.data){
          this.settingId=res.data.settingId
          this.switchValue = res.data.autoArrive;
          this.settlementDate = res.data.settlementDate;
          this.num = formatAmount(res.data.cashAmount);
          this.userId=res.data.userId
          this.userName=this.userId?res.data.userName:'超级管理员'
        }
      }).catch(function (err) {
      });
    },
    saveSetting(){
      console.log('用户',this.userId)
      let data = {
        settingId: this.settingId,
        autoArrive : this.switchValue,
        settlementDate : this.settlementDate,
        cashAmount: this.num,
        userId: this.userId,
      }
      updateSetting(data)
      .then(res=>{
        if(res.code == 200){
        this.$message({
          message: "保存成功",
          type: "success",
        });
        }
      })
    },
    selUser() {
      this.$refs.selUser.dialogVisible = true
    },
    changeKf(data) {
      console.log('data',data)
      this.userName=data.name
      this.userId=data.userId
      this.$refs.selUser.dialogVisible = false
    }

  }
};
</script>

<style lang="scss" scoped>
.myInput{
  width: 20%;
}
</style>
