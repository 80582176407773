<template>
 <div class="my_select_img_wrap">
  <el-dialog
    append-to-body
    :close-on-click-modal="false"
    :visible="dialogVisible"
    :before-close="closeDialog"
    title="选择客服人员"
    width="900px"
  >
  <el-table
      :data="tableData"
      border
      style="width: 100%">
      <el-table-column
        prop="cardId"
        label="id"
        align="center"
       >
      </el-table-column>
      <el-table-column
        prop="name"
        label="姓名"
        align="center"
       >
      </el-table-column>
      <el-table-column
        prop="phone"
        label="电话"
        align="center"
       >
      </el-table-column>
      <el-table-column
        align="center"
        label="操作"
      >
        <template slot-scope="scope">
           <el-button @click="confirmSelKf(scope.row)">选择</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :page-size.sync="params.pageSize"
      :total="total"
      :current-page.sync="params.pageNum"
      style="margin-top: 8px;"
      :page-sizes=pageSizes
      layout="total, prev, pager, next, sizes"
      @size-change="pageSizeChange($event)"
      @current-change="pageChange($event)"
    />
  </el-dialog>
 </div>

</template>

<script>

// import {get} from "@/utils/request";
import axios from 'axios'

import { fetchMembertList } from '@/api/customPage'
export default {
  components: {  },
  props: {

  },
  data() {
    return {
      dialogVisible: false,
      params: {
        pageNum: 1,
        pageSize: 10,
        companyId: localStorage.getItem('info')? JSON.parse(localStorage.getItem('info')).companyId : ''
      },
      total: 0,
      pageSizes: [12,16,20,24],
      tableData: [],
    }
  },
  mounted() {
     this.init()
  },
  methods: {
    pageChange(value){
      this.params.pageNum=value
      this.init()
    },
    pageSizeChange(value){
      this.params.pageSize=value
      this.init()
    },
    confirmSelKf(data) {
      this.$parent.changeKf(data)
    },
    closeDialog(){
      this.dialogVisible=false;
    },
    async init(){
      let result = await fetchMembertList(this.params)
      if(result.data) {
        this.total = result.data.total
        this.tableData = result.data.list
      }
    },
    handleClick(value){
    }
  }
}
</script>

<style rel="stylesheet/scss" lang="scss" scoped>

</style>
